import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FairPracticeCodeComponent } from './fair-practice-code/fair-practice-code.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PoliciesComponent } from './policies/policies.component';
import { PartnersComponent } from './partners/partners.component';
import { LoanEnquiryComponent } from './loan-enquiry/loan-enquiry.component';
import { UmangPrivacyPolicyComponent } from './umang-privacy-policy/umang-privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    AboutUsComponent,
    ContactUsComponent,
    FairPracticeCodeComponent,
    PrivacyPolicyComponent,
    PoliciesComponent,
    PartnersComponent,
    LoanEnquiryComponent,
    UmangPrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
